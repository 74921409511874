/** @define events;  weak */
.events {
  padding: 40px 0 20px 0;
}
@media only screen and (min-width: 1000px) {
  .events__wrapper {
    justify-content: center;
  }
}
.events__item {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  text-align: center;
}
@media only screen and (min-width: 1000px) {
  .events__item {
    align-items: flex-start;
    max-width: 360px;
  }
}
.events__item:hover {
  cursor: pointer;
}
.events__item:hover .link {
  color: #e36c1b;
}
@media only screen and (max-width: 599px) {
  .events__item {
    border-bottom: 1px solid #bebebe;
  }
}
@media only screen and (min-width: 600px) and (max-width: 999px) {
  .events__item {
    border-bottom: 1px solid #bebebe;
  }
  .events__item:nth-child(odd) {
    border-right: 1px solid #bebebe;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1199px) {
  .events__item {
    border-bottom: 1px solid #bebebe;
  }
  .events__item:nth-of-type(3n + 2) {
    border-left: 1px solid #bebebe;
  }
  .events__item:nth-of-type(3n - 1) {
    border-right: 1px solid #bebebe;
  }
  .events__item:last-child {
    border-right: none;
  }
}
@media only screen and (min-width: 1200px) {
  .events__item {
    border-bottom: 1px solid #bebebe;
  }
  .events__item:nth-of-type(2n + 2) {
    border-left: 1px solid #bebebe;
  }
  .events__item:nth-of-type(4n - 2) {
    border-right: 1px solid #bebebe;
  }
  .events__item:last-child {
    border-right: none;
  }
}
.events__item .title {
  margin-bottom: 24px;
  font-size: 20px;
}
.events__item .link {
  display: block;
  color: #3f59a2;
}
.events__item .link:hover {
  color: orange;
}
.events__content {
  height: 100%;
}
@media only screen and (min-width: 1000px) {
  .events__content {
    align-items: flex-start;
    text-align: left;
  }
}
/*.events__enterview {
  width: 100%;

  @media only screen and (min-width: 600px) {
    border-top: 1px solid @grey-5;
  }

  @media only screen and (max-width: 799px) {
    border-right: 1px solid @grey-5;
    border-left: 1px solid @grey-5;
  }
}*/
.events--view {
  /*.events__content {
    flex: 1;
  }*/
  /*.events__link {
    padding: 0 30px 30px;
  }*/
}
.events--view .events__item {
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 999px) {
  .events--view .events__item {
    width: 50%;
  }
}
@media only screen and (min-width: 1000px) {
  .events--view .events__item {
    width: calc(100% / 3);
  }
}
@media only screen and (min-width: 1200px) {
  .events--view .events__item {
    width: 25%;
  }
}
.events--view .events__picture {
  flex-basis: auto;
  margin-bottom: 30px;
}
/*.events--border {
  .events__item {
    border-bottom: 1px solid @grey-5;

    @media only screen and (min-width: 1100px) {
      padding: 40px;

      &:nth-child(even) {
        border-right: 1px solid @grey-5;
      }
    }
  }
}*/
/*.events__itemfirst {
  padding: 40px 0;

  border-bottom: 1px solid @grey-5;

  .events__item {
    width: 100%;

    border: none;

    @media only screen and (min-width: 900px) {
      flex: 1;
    }
  }
}*/
/*.events--detail {
  padding: 40px 0 20px 0;

  @media only screen and (min-width: 800px) {
    padding: 40px 0;
  }

  border-bottom: 1px solid #d0d7e8;

  .events__picture {
    width: 100%;
    margin-bottom: 20px;

    @media only screen and (min-width: 800px) {
      width: 50%;
      margin-bottom: 0;
    }

    @media only screen and (min-width: 1100px) {
      flex: 0 0 600px;
    }
  }

  .events__content {
    width: 100%;

    @media only screen and (max-width: 799px) {
      text-align: center;
    }

    @media only screen and (min-width: 800px) {
      width: 50%;
      padding-left: 40px;
    }

    @media only screen and (min-width: 1100px) {
      flex: 1;
    }
  }
}*/
/*# sourceMappingURL=css/events.css.map */